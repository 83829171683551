import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import DataViewer from '~utils/DataViewer'
import Section from '~components/Section'
import Masonry from 'react-masonry-css'
import RichText from '~components/RichText'
import Button from '~components/Button'
import Image from '~components/Image'
import SanityLink from '~components/SanityLink'
import resolveLink from '~utils/resolveLink'
import { DateTime } from 'luxon'

const NewsItem = ({ content }) => {
	const [hover, setHover] = useState(false)
	const articleTile = content._type === 'article'
	const date = DateTime.fromISO(content.publishedAt)

	return (
		<ItemWrap 
			link={articleTile ? { linkType: 'internal', document: content } : content.button?.link}
		>
			<Inner
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<NewsImage image={content.image}/>
				<div>
					<Title className='h3-serif'>
						{articleTile && <Date className='h3-serif'>{date.toLocaleString(DateTime.DATE_SHORT)} —</Date>}
						{content.title}</Title>
				</div>
				<Excerpt className='sm-sans'>
					<RichText content={content.excerpt}/>
				</Excerpt>
				{content?.tags?.length > 0 &&				
					<Tags>
						{content?.tags?.map(t => (
							<Tag key={t.title} color={t.colour}>{t.title}</Tag>
						))}
					</Tags>
				}
				{content.showButton || (articleTile && content.buttonText) && 
					<Button small className='sm-sans' hover={hover}>
						{articleTile ? content.buttonText : content.button?.text}
					</Button>
				}
			</Inner>
		</ItemWrap>
	)
}
	
const ItemWrap = styled(SanityLink)`
	display: block;
	margin-bottom: 72px;
	${mobile}{
		margin-bottom: var(--m-m);
		:last-of-type{
			margin-bottom: var(--m-l);
		}
	}
`
const Inner = styled.div`
	
`
const NewsImage = styled(Image)`
	margin-bottom: 28px;
	${mobile}{
		margin-bottom: var(--m-s)
	}
`
const Date = styled.div`
	display: inline-block;
	margin-right: 0.25em;
`
const Title = styled.div`
	display: inline-block;
	margin-bottom: 28px;
	${mobile}{
		margin-bottom: 22px;
	}
`
const Excerpt = styled.div`
	margin-bottom: 28px;
	${mobile}{
		margin-bottom: 18px;
	}
`
const Tags = styled.div`
	margin-bottom: 28px;
	${mobile}{
		margin-bottom: 18px;
	}
`
const Tag = styled.div`
	background-color: ${props => props.color};
	display: inline-block;
	padding: 8px 11px 4px;
	margin-right: 14px;
`

const News = ({ data }) => {
	const page = data?.sanityNewsPage

	const breakpointColumnsObj = {
		default: 4,
		1024: 2,
		768: 1,
	}

	return (
		<>
			<Seo 
				title={page?.title}
				metaTitle={page?.seo?.metaTitle}
				description={page?.seo?.metaDescription}
				image={page?.seo?.socialImage}
			/>
			<Header>
				<Container>
					<h1 className='h2-serif'>Branding & Design News</h1>
				</Container>
				<Line />
			</Header>
			<Section>
				<Grid 
					breakpointCols={breakpointColumnsObj} 
				>
					{page.newsItems.map(newsItem => (
						<NewsItem content={newsItem} key={newsItem?._key ?? newsItem.title}/>
					))}
				</Grid>
			</Section>
			<DataViewer data={page} name="page" />
		</>
	)
}

const Header = styled(Section)`
	margin-top: 140px;
	margin-bottom: var(--s);
	${mobile}{
		margin-top: 87px;
		margin-bottom: var(--m-s);
	}
`
const Container = styled.div`
	grid-column: span 16;
	margin-bottom: var(--m);
	${mobile}{
		margin-bottom: var(--m-m);
	}
`
const Line = styled.div`
	border-bottom: 1px solid var(--black);
	grid-column: span 16;
`
const Grid = styled(Masonry)`
	grid-column: span 16;
	display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
	> div {
		padding-left: 30px; /* gutter size */
  	background-clip: padding-box;
	}
`
News.propTypes = {
	data: PropTypes.object,
}

NewsItem.propTypes = {
	content: PropTypes.object,
}

export const query = graphql`
	  query NewsPageQuery($id: String) {
    	sanityNewsPage(id: {eq: $id}) {
				title
				slug {
					current
				}
				newsItems {
					... on SanityArticle {
						_type
						title
						publishedAt
						_key
						image {
							...imageWithAlt
						}
						buttonText
						tags{
							title
							colour
						}
						slug{
							current
						}
						excerpt: _rawExcerpt(resolveReferences: {maxDepth: 5})
					}
					... on SanityNewsItem {
						_type
						title
						_key
						image {
							...imageWithAlt
						}
						showButton
						excerpt: _rawExcerpt(resolveReferences: {maxDepth: 5})
						button {
							text
							link {
								...link
							}
						}
      		}
				}
				seo{
					...seo
				}
			}
		}
`


export default News